port module Main exposing (main)

import Browser
import Browser.Events
import Browser.Navigation as Nav
import Dict
import Element
import Http
import Json.Decode as Decode
import Json.Encode as Encode
import Model exposing (Flags, Model, Msg(..), UIState(..))
import Random
import RemoteData
import Util exposing (do, done, pipe2, pipe3, toDimensions)
import View


port signIn : Encode.Value -> Cmd msg


main : Program Flags Model Msg
main =
    Browser.application
        { init = pipe3 Model.initial done
        , view = View.doc
        , update = update
        , subscriptions =
            always (Browser.Events.onResize <| pipe2 toDimensions (Element.classifyDevice >> LayoutChange))
        , onUrlRequest = Navigate
        , onUrlChange = UrlChange
        }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        NoOp ->
            done model

        SignIn token user ->
            { model | user = Just user } |> do (token |> Encode.string |> signIn)

        CheckCusine ->
            let
                dropEmpty =
                    List.filter (String.trim >> (/=) "")

                check tag details =
                    { model | state = tag { details | cuisines = details.cuisines |> dropEmpty } } |> done
            in
            case model.state of
                Initial details ->
                    check Initial details

                Cuisine details ->
                    check Cuisine details

        LayoutChange device ->
            { model | device = device } |> done

        UrlChange _ ->
            done model

        Navigate urlReq ->
            case urlReq of
                Browser.Internal _ ->
                    done model

                Browser.External str ->
                    model |> do (Nav.load str)

        GoBack ->
            model |> Model.goBack |> done

        Request url details ->
            let
                allMenus =
                    model.plan.menus

                mapping =
                    Dict.fromList [ ( "restaurant", allMenus |> List.filter ((/=) "restaurant") ) ]

                buildMenus =
                    mapping |> Dict.get details.menuType |> Maybe.withDefault [ details.menuType ]

                ( newSeed, _ ) =
                    Random.step (Random.int Random.minInt Random.maxInt) model.seed

                dStatus =
                    { details | result = RemoteData.Loading }

                toPost menu =
                    Http.request
                        { method = "POST"
                        , headers =
                            model.user
                                |> Maybe.map (\{ token } -> [ Http.header "Authorization" ("Bearer " ++ token) ])
                                |> Maybe.withDefault []
                        , url = url
                        , expect =
                            Http.expectJson
                                (RemoteData.fromResult >> Response model.reqId dStatus)
                                (Decode.field "result" Model.menuDecoder)
                        , body =
                            Http.jsonBody
                                (Model.requestByCuisine model.user { details | menuType = menu })
                        , timeout = Nothing
                        , tracker = Nothing
                        }
            in
            { model | reqId = model.reqId + 1, seed = newSeed |> Random.initialSeed }
                |> Model.setState (Cuisine dStatus)
                |> do (Cmd.batch (buildMenus |> List.map toPost))

        Response reqId targetState res ->
            let
                newState =
                    model.state |> Model.updateResult (Cuisine { targetState | result = res })
            in
            model
                |> Model.setState newState
                |> Model.pushState reqId newState
                |> done

        Transition state ->
            model |> Model.setState state |> done

        RandomizeVariants ->
            let
                ( newSeed, _ ) =
                    Random.step (Random.int Random.minInt Random.maxInt) model.seed
            in
            { model | seed = newSeed |> Random.initialSeed } |> done
