import elements, { registerElements } from './elements';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { json, mapFlags } from './util';
import Result from '@ailabs/ts-utils/dist/result';
import { curry, reduce, assoc, pipe, pick } from 'ramda';

const { Elm } = require("./Main.elm");

let api: string;
if (process.env.NODE_ENV === 'production') {
  api = 'https://eight6fixe.onrender.com';
} else {
  api = 'http://localhost:3333';
}

const rename = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, Object.keys(obj))
);

const loading = setTimeout(() => {
  document.querySelector('article')!.classList.add('show');
}, 1500);

const token = localStorage.token;

fetch(`${api}/plan`, {
  mode: 'cors',
  headers: { Authorization: `Bearer ${token}` }
}).then(json).then((plan: any) => {
  clearTimeout(loading);
  document.body.removeChild(document.querySelector('article')!);

  const decoded = token
    ? Result.attempt(jwtDecode<JwtPayload>)(token).map(token => (
      (Date.now() - token.exp! * 1000) < 1000 * 60 * 60 * 24)
        ? token
        : null
      ).defaultTo(null)
    : null;

  const toUser = pipe(
    rename({ email_verified: 'verified', given_name: 'nickName', name: 'fullName' }),
    pick(['verified', 'email', 'fullName', 'nickName', 'picture'])
  );

  const app = Elm.Main.init({
    node: document.getElementsByTagName('body'),
    flags: mapFlags({
        api,
        plan,
        user: decoded ? Object.assign({ token }, toUser(decoded)) : null,
        initialSeed: Math.floor(Math.random() * 10_000),
        window: { width: window.innerWidth, height: window.innerHeight }
      }
    )
  });

  app.ports.signIn.subscribe((token: string) => {
    localStorage.token = token;

    fetch(`${api}/users`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Authorization": `Bearer ${token}`
      }
     });

  });
});

registerElements(elements);