import { LitElement, html } from 'lit';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { mergeRight, pipe, prop } from 'ramda';

declare const google: any;

export const decode = jwtDecode;

export const load = (url: string) => new Promise<Event>((resolve) => {
  const script = Object.assign(document.createElement('script'), {
    async: true,
    defer: true,
    onload: resolve
  });

  /**
   * `src` is assigned and tag is attached after everything else is set.
   */
  script.src = url;
  document.head.appendChild(script);
});

const googleClient = load('https://accounts.google.com/gsi/client');

export class GoogleSignIn extends LitElement {

  clientId!: string;

  static properties = {
    clientId: { type: String, attribute: 'client-id' }
  }

  firstUpdated() {
    googleClient.then(() => {
      google.accounts.id.initialize({
        client_id: this.clientId,
        callback: pipe(
          prop<any>('credential'),
          (token: string) => mergeRight(jwtDecode<JwtPayload>(token), { token }),
          this.event.bind(this, 'auth'),
        )
      });

      google.accounts.id.renderButton(this.querySelector('.google-sign-in-button-wrapper'), {
        theme: "outline",
        size: "large"
      });

      // Display the One Tap dialog
      // google.accounts.id.prompt();
    });
  }

  private event<Info extends object>(name: string, info: Info) {
    this.dispatchEvent(Object.assign(new Event(name, {}), info));
  }

  /**
   * Disable Shadow DOM
   */
  createRenderRoot() {
    return this;
  }

  render() {
    return html`<div class="google-sign-in-button-wrapper"></div>`;
  }
}
