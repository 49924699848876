import { toPairs } from "ramda";

import { Payment } from "./Payment";
import { Typeahead } from "./Typeahead";
import { GoogleSignIn } from "./GoogleSignIn";

export default {
  // "tag-name": Class
  'pep-typeahead': Typeahead,
  'pep-payment': Payment,
  'google-sign-in': GoogleSignIn
};

export type Elements = {
  [key: string]: CustomElementConstructor
};

export const registerElements = (elements: Elements) => {
  try {
    toPairs(elements).forEach(([tag, cls]) => customElements.define(tag, cls));
  } catch (e) {
    window.history.go(0);
  }
}
