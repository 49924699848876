import { LitElement, html } from 'lit';
import { live } from 'lit/directives/live.js';
import { loadStripe } from '@stripe/stripe-js';

export class Payment extends LitElement {

  static properties = {
  }

  constructor() {
    super();
    Object.assign(this, {});
  }

  /**
   * Disable Shadow DOM
   */
  createRenderRoot() {
    return this;
  }

  render() {
    return html`<div class="payment-wrapper">
      Hello!
    </div>`;
  }
}
